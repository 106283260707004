import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetail: null, // 登入後回傳的使用者資訊
  },
  reducers: {
    setUser: (state, action) => {
      if (action.payload) {
        localStorage.setItem("user", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("user");
        // 移除分頁設定
        sessionStorage.removeItem("pageSetting");
      }

      state.userDetail = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
